// Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback');

// Variables
@import 'variables';

//Font Awesome
$fa-font-path: "../webfonts";

// Bootstrap
// @import '~bootstrap/scss/bootstrap';

// Admin LTE
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.css';
@import '~admin-lte/dist/css/adminlte.css';

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
